<template>
  <div
    class="h-screen flex w-full vx-row no-gutter items-center justify-center page"
    style="color: #626262"
  >
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/3 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="card-bg page-card">
          <div v-if="!isLoading && !isSuccess">
            <div style="margin-bottom: 25px">
              <img
                src="@/application/assets/images/logo/dnaskills.png"
                style="height: 128px; margin: auto"
              />
            </div>
            <div class="header-text">
              <h3>{{ $l.get("password-reset-request.header") }}</h3>
            </div>
            <!-- <div class="centerx"> -->
            <form @submit.prevent="onSubmit">
              <vs-input
                :label-placeholder="$l.get('dictionary.email', 'capitalize')"
                :danger="isEmailInvalid"
                :danger-text="
                  $l.get('errors.input-invalid-email', 'capitalize')
                "
                v-model="email"
              />

              <!-- <div style="margin-top: 25px; margin-bottom: 25px;">            
            <vs-checkbox>{{ $l.get("login.remember-checkbox") }}</vs-checkbox>            
              </div>-->

              <div style="margin-top: 25px; margin-bottom: 25px">
                <vs-button color="primary" type="filled" @click="onSubmit">{{
                  $l.get("dictionary.submit")
                }}</vs-button>
              </div>
              <input
                type="submit"
                style="position: absolute; left: -9999px; width: 1px; height: 1px;"
                tabindex="-1"
              />
            </form>
            <vs-divider>{{ $l.get("dictionary.or", "upper") }}</vs-divider>
            <vs-button
              color="primary"
              type="flat"
              @click="onLoginButtonClick"
              >{{ $l.get("welcome.login-button") }}</vs-button
            >
          </div>
          <div
            class="animated fadeIn"
            style="text-align: center !important"
            v-if="isLoading && !isSuccess"
          >
            <Loader
              :label="$l.get('password-reset-request.loader', 'capitalize')"
            />
          </div>
          <div
            class="animated fadeIn"
            style="text-align: center !important"
            v-if="!isLoading && isSuccess"
          >
            <feather-icon icon="MailIcon" style="margin-bottom: 10px" />
            <p>{{ $l.get("password-reset-request.success", "capitalize") }}</p>
            <vs-button
              color="primary"
              type="flat"
              style="margin-top: 10px"
              @click="onLoginButtonClick"
              >{{ $l.get("welcome.login-button") }}</vs-button
            >
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import SelectLoginInteractor from "@/business/password-reset-request/select-login";
import SubmitPasswordResetRequestInteractor from "@/business/password-reset-request/submit-password-reset-request";
import PasswordResetRequestScreenController from "@/adapters/controllers/screen-password-reset-request";
import { UserPasswordResetRequest } from "@/domain/users/models";

import Loader from "@/application/components/Loader";

export default {
  screen_name: "password-reset-request",
  components: { Loader },
  data() {
    return {
      isEmailInvalid: false,
      email: "",
      isLoading: false,
      isSuccess: false,
      controller: null,
      interactors: {
        selectLogin: null,
        submitPasswordResetRequest: null
      },
      data: null
    };
  },
  beforeMount() {
    this.controller = this.$injector.get(PasswordResetRequestScreenController);

    //{ INTERACTORS
    this.interactors.submitPasswordResetRequest = this.$injector.get(
      SubmitPasswordResetRequestInteractor
    );
    this.interactors.selectLogin = this.$injector.get(SelectLoginInteractor);
    //} INTERACTORS

    this.controller.setData = this.setData;
    this.controller.getData = this.getData;
    this.controller.setAsLoading = this.setAsLoading;
    this.controller.setAsLoaded = this.setAsLoaded;
    this.controller.setAsEmailInvalid = this.setAsEmailInvalid;
    this.controller.clearFormErrors = this.clearFormErrors;
    this.controller.setAsSuccess = this.setAsSuccess;
  },
  mounted() {},
  methods: {
    setData(data) {
      this.data = data;
    },
    getData() {
      return this.data;
    },
    setAsLoading() {
      this.isLoading = true;
    },
    setAsLoaded() {
      this.isLoading = false;
    },
    setAsSuccess() {
      this.isSuccess = true;
    },
    clearFormErrors() {
      this.isEmailInvalid = false;
    },
    setAsEmailInvalid() {
      this.isEmailInvalid = true;
    },
    onLoginButtonClick() {
      this.interactors.selectLogin.handle();
    },
    onSubmit() {
      this.interactors.submitPasswordResetRequest.handle(
        new UserPasswordResetRequest(this.email)
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.card-bg .vs-con-input-label {
  width: 100% !important;
  margin-top: 25px;
}
.card-bg .vs-button {
  width: 100% !important;
}
.header-text {
  margin-top: 30px;
  margin-bottom: 40px;
  text-align: center;
}
.inputs .vs-input {
  margin: 15px;
}
</style>
